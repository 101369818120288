import React, { useState } from "react";
import "./css/Slider.css";
import axios from "axios";
import { Modal } from "react-bootstrap";
import certificate from '../Assets/Img/certificate.png'
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import harghar from '../Assets/Img/har-ghar-tiranga-img.png'


const Slider = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [btnVisible, setBtnVisible] = useState(false)
    const [show, setShow] = useState(false);
    const [download, setDownload] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleForm = (e) => {
        e.preventDefault();
        if (isNaN(phone)) {
            alert('Invalid Number')
        }
        else {
            setBtnVisible(true)
            const data = {
                Name: name,
                Phone: phone
            }
            axios.post('https://sheet.best/api/sheets/c4b51300-e301-493c-864e-280a66e2cd71', data).then((response) => {
                console.log(response);
                handleShow();
                setBtnVisible(false)
            })
        }
    };
    const handleCaptureClick = async () => {
        const canvas = await html2canvas(document.querySelector("#cert"));
        const dataURL = canvas.toDataURL('image/png');
        downloadjs(dataURL, name.trim(), 'image/png')
        setShow(false)
    };
    return (
        <div className="slider">
            <div className="bg">
                <div id="landing-header">
                    <div className="container">
                        <div className="row" id="form">
                            <div className="col-lg-4">
                                <form className="form" onSubmit={handleForm}>
                                    <div className="form-group">
                                        <p>Here you can pin a virtual flag at your location to mark your contribution</p>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="pb-1">Name*</label>
                                        <input type='text' className='form-control' autoComplete='off' name='from_name' required={true} placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label className="pb-1">Mobile No*</label>
                                        <input type={'text'} maxLength={'10'} minLength={'10'} className='form-control' required={true} placeholder="Mobile No" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                    </div>
                                    <input hidden={btnVisible} className="btn mt-4 btn-primary form-control" style={{ backgroundColor: '#005697' }} type="submit" value="Submit" />
                                    <div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-4"></div>
                            <div className="col-lg-4"></div>
                        </div>
                        <div className="row pt-4">
                            <div className="col-lg-12">
                                <img src={harghar} alt='Har Ghar Tiranga' className='img-fluid mb-4' />
                                <h3>HOIST A FLAG AT YOUR HOUSE FROM <br />
                                    13-15 AUGUST 2022</h3>
                                <p>
                                    SHOW YOUR COMMITMENT BY PINNING A FLAG.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: '#d84727' }}>Congratulations !</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    {download ?
                        <>
                            <div id='cert'>
                                <img src={certificate} alt='Certificate' className='img-thumbnail' />
                                <div class="centered">{name}</div>
                            </div>
                        </> :
                        <>
                            <h2>Your flag has been pinned</h2>
                            <a href="#/" style={{ color: '#009E6C', textDecoration: 'none' }} onClick={() => setDownload(true)}>Download Certificate</a>
                        </>
                    }
                </Modal.Body>
                {download ?
                    <Modal.Footer>
                        <button onClick={handleCaptureClick} className='btn-primary btn'>
                            Download
                        </button>
                    </Modal.Footer> : null
                }
            </Modal>
        </div>
    );
};

export default Slider;