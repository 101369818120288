import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import logo from '../Assets/Img/har-ghar-tiranga-logo.png'
import "./css/Navbar.css";

const NavbarComp = () => {
    return (
        <Navbar collapseOnSelect style={{ paddingTop: 0, paddingBottom: 0, }} expand="lg" variant="dark">
            <Container>
                <Navbar.Brand>
                    <img src={logo} className="logo" alt='Har Ghar Tiranga' /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ boxShadow: 'none', border: 'none', backgroundColor: 'red' }} />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav>
                        <NavLink to={'/'} className='menu'>Home</NavLink>
                        <NavLink to={'/Contact'} className='menu2'>Contact Us</NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavbarComp