import React from 'react'
import NavbarComp from '../Component/NavbarComp'
import Slider from '../Component/Slider'

const Home = () => {
  return (
    <>
      <NavbarComp />
      <Slider />
    </>
  )
}

export default Home